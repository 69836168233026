import { Layout } from '@postscript/components';
import { PackageTypes } from 'components/billing/common/types';
import {
  useGetAvailablePackages,
  useGetCurrentPlan,
  useGetNextPlan,
} from 'components/billing/context/useBilling';
import styled from 'styled-components';
import { PACKAGES } from '../../common/constants';
import { isMinSpendStarterPlan } from './helpers';
import Package from './Package';
import PlanChangeBanner from './PlanChangeBanner';

const StyledPackages = styled(Layout).attrs({
  valign: 'top',
  gap: '0',
})`
  align-items: stretch;
  border: 2px solid var(--border-color-dim);
  border-radius: var(--border-radius-medium);
  overflow: hidden;
  margin-bottom: var(--spacing-9);
  overflow-x: auto;
`;

export default function MarketingPlansList(): JSX.Element | null {
  const { data: currentPlan } = useGetCurrentPlan();
  const { data: nextPlan } = useGetNextPlan();
  const { data: packages = [] } = useGetAvailablePackages();
  const availablePackages = packages.map((packageInstance) => ({
    ...packageInstance,
    plan: packageInstance.availablePlan,
    ...(PACKAGES[packageInstance.name as PackageTypes] || { features: [] }),
  }));

  return (
    <>
      <PlanChangeBanner />
      <StyledPackages>
        {availablePackages.map(
          ({
            id,
            name,
            description,
            plan,
            features,
            hasFreeTrialCTA,
            includedFeaturesPlanName,
          }) => (
            <Package
              name={name}
              description={description}
              hasFreeTrialCTA={hasFreeTrialCTA}
              disclaimerText={
                isMinSpendStarterPlan(plan)
                  ? '$25 minimum monthly spend'
                  : undefined
              }
              plan={plan}
              includedFeaturesPlanName={includedFeaturesPlanName}
              features={features}
              isCurrent={!!(plan && plan.id === currentPlan?.id)}
              isNext={!!(plan && plan.id === nextPlan?.id)}
              isUpgrade={
                !!(
                  plan && (currentPlan?.saasFeeCents ?? 0) <= plan.saasFeeCents
                )
              }
              isDowngrade={
                !!(plan && (currentPlan?.saasFeeCents ?? 0) > plan.saasFeeCents)
              }
              key={id}
            />
          ),
        )}
      </StyledPackages>
    </>
  );
}
